import { SelectOption } from '@app/models';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { ReportType } from '../reports/report.model';

export enum PeriodType {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  LAST_WEEK = 'last-week',
  LAST_CALENDAR_MONTH = 'last-calendar-month',
  LAST_7_DAYS = 'last-7-days',
  LAST_30_DAYS = 'last-30-days',
  TOMORROW = 'tomorrow',
  NEXT_WEEK = 'next-week',
  NEXT_CALENDAR_MONTH = 'next-calendar-month',
  NEXT_7_DAYS = 'next-7-days',
  NEXT_30_DAYS = 'next-30-days',
}

export enum RecurrenceInterval {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
}

export enum RecurrenceDayOfWeek {
  MONDAY = 'mo',
  TUESDAY = 'tu',
  WEDNESDAY = 'we',
  THURSDAY = 'th',
  FRIDAY = 'fr',
  SATURDAY = 'sa',
  SUNDAY = 'su',
}

export interface ReportProjectionsRequestModel {
  periodSettings: {
    type: PeriodType;
  };
  recurrenceSettings: {
    interval: string;
    runTime: string;
    dayOfWeek: RecurrenceDayOfWeek;
  };
}

export interface ReportRecurringRequestModel extends ReportProjectionsRequestModel {
  name: string;
  reportSettings: {
    type: ReportType;
    parameters: Record<string, unknown>;
  };
}

export interface ReportProjectionsResponseModel {
  runTime: string;
  reportPeriod: {
    startDate: string;
    endDate: string;
  };
}

export interface ReportRecurringModel extends ReportRecurringRequestModel {
  id: string;
  ownerId: string;
  name: string;
  nextRun: string;
}

export const recurringPeriod: SelectOption[] = [
  { label: _('Today'), value: 'today' },
  { label: _('Previous day'), value: 'yesterday' },
  { label: _('Previous week'), value: 'last-week' },
  { label: _('Previous calendar month'), value: 'last-calendar-month' },
  { label: _('Previous 7 days'), value: 'last-7-days' },
  { label: _('Previous 30 days'), value: 'last-30-days' },

  { label: _('Next day'), value: 'tomorrow' },
  { label: _('Next week'), value: 'next-week' },
  { label: _('Next calendar month'), value: 'next-calendar-month' },
  { label: _('Next 7 days'), value: 'next-7-days' },
  { label: _('Next 30 days'), value: 'next-30-days' },
];

export const recurringInterval: SelectOption[] = [
  { label: _('Daily'), value: 'daily' },
  { label: _('Weekly'), value: 'weekly' },
  { label: _('Monthly'), value: 'monthly' },
  { label: _('Quarterly'), value: 'quarterly' },
];

export const recurringDayOfWeek: SelectOption[] = [
  { label: _('Monday'), value: 'mo' },
  { label: _('Tuesday'), value: 'tu' },
  { label: _('Wednesday'), value: 'we' },
  { label: _('Thursday'), value: 'th' },
  { label: _('Friday'), value: 'fr' },
  { label: _('Saturday'), value: 'sa' },
  { label: _('Sunday'), value: 'su' },
];
